import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  SwipeableDrawer,
  ListItemIcon,
  Badge,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  AccountCircle,
  ExitToApp,
  Notifications,
} from "@material-ui/icons";
import { logout } from "../../actions";
import getColor from "./getColor";
import settings from "./settings";
import baseurl from "../../api/baseurl";
const drawerWidth = 295;
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  title: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },

  drawerDesktop: {
    display: "none",
    flexShrink: 0,
    [theme.breakpoints.up("md")]: {
      display: "block",
      width: drawerWidth,
    },
  },

  drawerPaper: {
    width: drawerWidth,
  },

  drawerPaperDesktop: {
    width: drawerWidth,
    zIndex: 1,
    backgroundColor: "transparent",
    border: 0,
  },

  content: {
    flexGrow: 1,
    paddingTop: 60,
    [theme.breakpoints.up("md")]: {
      marginLeft: drawerWidth,
    },
  },
}));

const Navbar = (props) => {
  const { children, decoded, logout, profile, notice, readNotice, grievance } =
    props;
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openMenu, setOpenMenu] = useState(null);
  const location = useLocation().pathname;

  const adminSideBarList = [
    {
      path: "/",
      name: "Dashboard",
      whiteIcon: "/MenuIcons/dashboardwhite.png",
      blackIcon: "/MenuIcons/dashboardblack.png",
      show: true,
    },
    {
      path: "/admissions",
      name: "Admission",
      whiteIcon: "/MenuIcons/admissionwhite.png",
      blackIcon: "/MenuIcons/admissionblack.png",
      show:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("H"))
          ? true
          : false,
    },
    {
      path: "/fee-management",
      name: "Fee Management",
      whiteIcon: "/MenuIcons/feemanagementwhite.png",
      blackIcon: "/MenuIcons/feemanagementblack.png",
      show:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("D"))
          ? true
          : false,
    },
    {
      path: "/student-management",
      name: "Student Management",
      whiteIcon: "/MenuIcons/studentmanagementwhite.png",
      blackIcon: "/MenuIcons/studentmanagementblack.png",
      show:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("A"))
          ? true
          : false,
    },
    {
      path: "/staff-management",
      name: "Staff Management",
      whiteIcon: "/MenuIcons/staffmanagementwhite.png",
      blackIcon: "/MenuIcons/staffmanagementblack.png",
      show:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("B"))
          ? true
          : false,
    },
    {
      path: "/routine-management",
      name: "Routine Management",
      whiteIcon: "/MenuIcons/routinemanagementwhite.png",
      blackIcon: "/MenuIcons/routinemanagementblack.png",
      show:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("E"))
          ? true
          : false,
    },
    {
      path: "/result-analysis",
      name: "Result Analysis",
      whiteIcon: "/MenuIcons/reportwhite.png",
      blackIcon: "/MenuIcons/reportblack.png",
      show:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("J"))
          ? true
          : false,
    },
    {
      path: "/raac",
      name: "RAAC Portal",
      whiteIcon: "/MenuIcons/raacwhite.png",
      blackIcon: "/MenuIcons/raacblack.png",
      show:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("L"))
          ? true
          : false,
    },
    {
      path: "/iqac",
      name: "IQAC Portal",
      whiteIcon: "/MenuIcons/iqacwhite.png",
      blackIcon: "/MenuIcons/iqacblack.png",
      show:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("G"))
          ? true
          : false,
    },
    {
      path: "/clubs",
      name: "Extensions/Clubs Portal",
      whiteIcon: "/MenuIcons/clubwhite.png",
      blackIcon: "/MenuIcons/clubblack.png",
      show:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("M"))
          ? true
          : false,
    },
    {
      path: "/rusa",
      name: "RUSA Portal",
      whiteIcon: "/MenuIcons/rusawhite.png",
      blackIcon: "/MenuIcons/rusablack.png",
      show:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("I"))
          ? true
          : false,
    },
    {
      path: "/grievance",
      name: "Grievances",
      whiteIcon: "/MenuIcons/grivancewhite.png",
      blackIcon: "/MenuIcons/grivanceblack.png",
      show:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("C"))
          ? true
          : false,
    },
    {
      path: "/student-union",
      name: "Students' Union",
      whiteIcon: "/MenuIcons/suwhite.png",
      blackIcon: "/MenuIcons/sublack.png",
      show:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("N"))
          ? true
          : false,
    },
    {
      path: "/news",
      name: "College News",
      whiteIcon: "/MenuIcons/collegenewswhite.png",
      blackIcon: "/MenuIcons/collegenewsblack.png",
      show:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("F"))
          ? true
          : false,
    },
    {
      path: "/notice",
      name: "College Notice",
      whiteIcon: "/MenuIcons/collegenotificationwhite.png",
      blackIcon: "/MenuIcons/collegenotificationblack.png",
      show:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("F"))
          ? true
          : false,
    },
    {
      path: "/activities",
      name: "College Activities",
      whiteIcon: "/MenuIcons/collegeactivities.png",
      blackIcon: "/MenuIcons/collegeactivitiesblack.png",
      show:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("F"))
          ? true
          : false,
    },
    {
      path: "/calendar",
      name: "College Calendar",
      whiteIcon: "/MenuIcons/collegecalendarwhite.png",
      blackIcon: "/MenuIcons/collegecalendarblack.png",
      show:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("F"))
          ? true
          : false,
    },

    {
      path: "/creation-master",
      name: "Creation Master",
      whiteIcon: "/MenuIcons/creationmasterwhite.png",
      blackIcon: "/MenuIcons/creationmasterblack.png",
      show:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("K"))
          ? true
          : false,
    },
    {
      path: "/developer-notification",
      name: "Notification From Developers",
      whiteIcon: "/MenuIcons/notificationfromdevus.png",
      blackIcon: "/MenuIcons/notificationfromdevusblack.png",
      show: true,
    },
    {
      path: "/support",
      name: "Support",
      whiteIcon: "/MenuIcons/supportwhite.png",
      blackIcon: "/MenuIcons/supportblack.png",
      show: true,
    },
    {
      path: "/tutorials",
      name: "Tutorials",
      whiteIcon: "/MenuIcons/tutorialwhite.png",
      blackIcon: "/MenuIcons/tutorialblack.png",
      show: true,
    },
  ];

  const facultySideBar = [
    {
      path: "/",
      name: "Dashboard",
      whiteIcon: "/MenuIcons/dashboardwhite.png",
      blackIcon: "/MenuIcons/dashboardblack.png",
      show: true,
    },
    {
      path: "/admissions",
      name: "Admission",
      whiteIcon: "/MenuIcons/admissionwhite.png",
      blackIcon: "/MenuIcons/admissionblack.png",
      show: profile.type === "Head Of Department" ? true : false,
    },
    {
      path: "/student-management",
      name: "Student Management",
      whiteIcon: "/MenuIcons/studentmanagementwhite.png",
      blackIcon: "/MenuIcons/studentmanagementblack.png",
      show: true,
    },
    {
      path: "/mentoring",
      name: "Mentoring",
      whiteIcon: "/MenuIcons/mentoringwhite.png",
      blackIcon: "/MenuIcons/mentoringblack.png",
      show: true,
    },
    {
      path: "/raac",
      name: "RAAC",
      whiteIcon: "/MenuIcons/raacwhite.png",
      blackIcon: "/MenuIcons/raacblack.png",
      show: true,
    },
    {
      path: "/leave",
      name: "Leave",
      whiteIcon: "/MenuIcons/leaveapplicationwhite.png",
      blackIcon: "/MenuIcons/leaveapplicationblack.png",
      show: true,
    },

    {
      path: "/result-analysis",
      name: "Result Analysis",
      whiteIcon: "/MenuIcons/reportwhite.png",
      blackIcon: "/MenuIcons/reportblack.png",
      show: true,
    },
    {
      path: "/iqac",
      name: "IQAC Portal",
      whiteIcon: "/MenuIcons/iqacwhite.png",
      blackIcon: "/MenuIcons/iqacblack.png",
      show: true,
    },

    {
      path: "/routine",
      name: "Routine",
      whiteIcon: "/MenuIcons/routinemanagementwhite.png",
      blackIcon: "/MenuIcons/routinemanagementblack.png",
      show: true,
    },
    {
      path: "/student-union",
      name: "Students' Union",
      whiteIcon: "/MenuIcons/suwhite.png",
      blackIcon: "/MenuIcons/sublack.png",
      show: true,
    },
    {
      path: "/news",
      name: "College News",
      whiteIcon: "/MenuIcons/collegenewswhite.png",
      blackIcon: "/MenuIcons/collegenewsblack.png",
      show: true,
    },
    {
      path: "/notice",
      name: "College Notice",
      whiteIcon: "/MenuIcons/collegenotificationwhite.png",
      blackIcon: "/MenuIcons/collegenotificationblack.png",
      show: true,
    },
    {
      path: "/activities",
      name: "College Activities",
      whiteIcon: "/MenuIcons/collegeactivities.png",
      blackIcon: "/MenuIcons/collegeactivitiesblack.png",
      show: true,
    },
    {
      path: "/calendar",
      name: "College Calendar",
      whiteIcon: "/MenuIcons/collegecalendarwhite.png",
      blackIcon: "/MenuIcons/collegecalendarblack.png",
      show: true,
    },
    {
      path: "/tutorials",
      name: "Tutorials",
      whiteIcon: "/MenuIcons/tutorialwhite.png",
      blackIcon: "/MenuIcons/tutorialblack.png",
      show: true,
    },
  ];
  const studentSideBar = [
    {
      path: "/",
      name: "Dashboard",
      whiteIcon: "/MenuIcons/dashboardwhite.png",
      blackIcon: "/MenuIcons/dashboardblack.png",
      show: true,
    },

    {
      path: "/academic",
      name: "My Academic Data",
      whiteIcon: "/MenuIcons/studentmanagementwhite.png",
      blackIcon: "/MenuIcons/studentmanagementblack.png",
      show: true,
    },
    {
      path: "/online-class",
      name: "Online Class",
      whiteIcon: "/MenuIcons/raacwhite.png",
      blackIcon: "/MenuIcons/raacblack.png",
      show: true,
    },
    {
      path: "/study-material",
      name: "Study Materials",
      whiteIcon: "/MenuIcons/studymaterialwhite.png",
      blackIcon: "/MenuIcons/studymaterial.png",
      show: true,
    },
    {
      path: "/fee",
      name: "Fees",
      whiteIcon: "/MenuIcons/feemanagementwhite.png",
      blackIcon: "/MenuIcons/feemanagementblack.png",
      show: true,
    },
    {
      path: "/routine",
      name: "Routine",
      whiteIcon: "/MenuIcons/routinemanagementwhite.png",
      blackIcon: "/MenuIcons/routinemanagementblack.png",
      show: true,
    },
    {
      path: "/teacher-on-leave",
      name: "Teacher On Leave",
      whiteIcon: "/MenuIcons/leaveapplicationwhite.png",
      blackIcon: "/MenuIcons/leaveapplicationblack.png",
      show: true,
    },

    {
      path: "/grievance",
      name: "Grievance",
      whiteIcon: "/MenuIcons/grivancewhite.png",
      blackIcon: "/MenuIcons/grivanceblack.png",
      show: true,
    },
    {
      path: "/student-union",
      name: "Students' Union",
      whiteIcon: "/MenuIcons/suwhite.png",
      blackIcon: "/MenuIcons/sublack.png",
      show: true,
    },
    {
      path: "/notice",
      name: "College Notice",
      whiteIcon: "/MenuIcons/collegenotificationwhite.png",
      blackIcon: "/MenuIcons/collegenotificationblack.png",
      show: true,
    },
    {
      path: "/news",
      name: "College News",
      whiteIcon: "/MenuIcons/collegenewswhite.png",
      blackIcon: "/MenuIcons/collegenewsblack.png",
      show: true,
    },
    {
      path: "/activities",
      name: "College Activities",
      whiteIcon: "/MenuIcons/collegeactivities.png",
      blackIcon: "/MenuIcons/collegeactivitiesblack.png",
      show: true,
    },
    {
      path: "/calendar",
      name: "College Calendar",
      whiteIcon: "/MenuIcons/collegecalendarwhite.png",
      blackIcon: "/MenuIcons/collegecalendarblack.png",
      show: true,
    },
    {
      path: "/my-classmates",
      name: "My Classmates",
      whiteIcon: "/MenuIcons/studentmanagementwhite.png",
      blackIcon: "/MenuIcons/studentmanagementblack.png",
      show: true,
    },
    {
      path: "/faculties",
      name: "Faculties",
      whiteIcon: "/MenuIcons/staffmanagementwhite.png",
      blackIcon: "/MenuIcons/staffmanagementblack.png",
      show: true,
    },

    {
      path: "/my-college",
      name: "My College",
      whiteIcon: "/MenuIcons/collegecalendarwhite.png",
      blackIcon: "/MenuIcons/collegecalendarblack.png",
      show: true,
    },
    {
      path: "/tutorials",
      name: "Tutorials",
      whiteIcon: "/MenuIcons/tutorialwhite.png",
      blackIcon: "/MenuIcons/tutorialblack.png",
      show: true,
    },
  ];

  const renderAdminSideBar = () => {
    return adminSideBarList
      .filter((item) => item.show)
      .map((item, index) => (
        <ListItem
          key={item.path}
          style={{
            backgroundColor:
              item.path === "/"
                ? location === "/" && "#fff"
                : location.includes(item.path) && "#fff",
            borderTopLeftRadius: 18,
            borderBottomLeftRadius: 18,
          }}
          button
          onClick={() => {
            setOpenDrawer(false);
          }}
          component={Link}
          to={item.path}
        >
          <ListItemIcon>
            <Badge
              badgeContent={
                item.path === "/grievance"
                  ? grievance.length &&
                    grievance.filter((a) => !a.redressal).length
                  : 0
              }
              color="error"
            >
              <img
                style={{ width: 20, height: 20 }}
                alt={item.name}
                src={
                  item.path === "/"
                    ? location === "/"
                      ? item.blackIcon
                      : item.whiteIcon
                    : location.includes(item.path)
                    ? item.blackIcon
                    : item.whiteIcon
                }
              />
            </Badge>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                color="primary"
                style={{
                  fontWeight: 500,
                  color:
                    item.path === "/"
                      ? location === "/"
                        ? "#222"
                        : "#fff"
                      : location.includes(item.path)
                      ? "#222"
                      : "#fff",
                }}
              >
                {item.name}
              </Typography>
            }
          />
        </ListItem>
      ));
  };

  const renderFacultySideBar = () => {
    return facultySideBar
      .filter((item) => item.show)
      .map((item, index) => (
        <ListItem
          key={item.path}
          style={{
            backgroundColor:
              item.path === "/"
                ? location === "/" && "#fff"
                : location.includes(item.path) && "#fff",
            borderTopLeftRadius: 18,
            borderBottomLeftRadius: 18,
          }}
          button
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to={item.path}
        >
          <ListItemIcon>
            <img
              style={{ width: 20, height: 20 }}
              alt={item.name}
              src={
                item.path === "/"
                  ? location === "/"
                    ? item.blackIcon
                    : item.whiteIcon
                  : location.includes(item.path)
                  ? item.blackIcon
                  : item.whiteIcon
              }
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                color="primary"
                style={{
                  fontWeight: 500,
                  color:
                    item.path === "/"
                      ? location === "/"
                        ? "#222"
                        : "#fff"
                      : location.includes(item.path)
                      ? "#222"
                      : "#fff",
                }}
              >
                {item.name}
              </Typography>
            }
          />
        </ListItem>
      ));
  };
  const renderStudentSideBar = () => {
    return studentSideBar
      .filter((item) => item.show)
      .map((item, index) => (
        <ListItem
          key={item.path}
          style={{
            backgroundColor:
              item.path === "/"
                ? location === "/" && "#fff"
                : location.includes(item.path) && "#fff",
            borderTopLeftRadius: 18,
            borderBottomLeftRadius: 18,
          }}
          button
          onClick={() => setOpenDrawer(false)}
          component={Link}
          to={item.path}
        >
          <ListItemIcon>
            <img
              style={{ width: 20, height: 20 }}
              alt={item.name}
              src={
                item.path === "/"
                  ? location === "/"
                    ? item.blackIcon
                    : item.whiteIcon
                  : location.includes(item.path)
                  ? item.blackIcon
                  : item.whiteIcon
              }
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                color="primary"
                style={{
                  fontWeight: 500,
                  color:
                    item.path === "/"
                      ? location === "/"
                        ? "#222"
                        : "#fff"
                      : location.includes(item.path)
                      ? "#222"
                      : "#fff",
                }}
              >
                {item.name}
              </Typography>
            }
          />
        </ListItem>
      ));
  };

  const renderMenu = () => {
    return (
      <Menu
        id="account-menu"
        style={{ marginTop: 30, marginLeft: -40 }}
        anchorEl={openMenu}
        open={Boolean(openMenu)}
        onClose={() => setOpenMenu(false)}
      >
        <MenuItem
          style={{ width: 170, fontWeight: 500 }}
          component={Link}
          to="/account"
          onClick={() => setOpenMenu(false)}
        >
          <AccountCircle style={{ marginRight: 10 }} />
          My Account
        </MenuItem>
        <MenuItem
          style={{ width: 170, fontWeight: 500 }}
          onClick={() => {
            setOpenMenu(false);
            logout();
          }}
        >
          <ExitToApp style={{ marginRight: 10 }} />
          Logout
        </MenuItem>
      </Menu>
    );
  };

  return (
    <div className={classes.grow}>
      <AppBar
        position="fixed"
        style={{
          zIndex: 2,
          boxShadow: "0px 1px 2px #ddd",
          backgroundColor: "#fff",
        }}
      >
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="primary"
            aria-label="open drawer"
            onClick={(e) => {
              setOpenDrawer(true);
            }}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/" style={{ textDecoration: "none" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt={settings.name}
                src="/logo512.png"
                style={{
                  objectFit: "contain",
                  height: 30,
                  width: 30,
                  border: "1px solid #ddd",
                  borderRadius: "50%",
                }}
              />{" "}
              <Typography
                className={classes.title}
                style={{
                  color: "#000",
                  fontWeight: 600,
                  fontSize: 16,
                  marginLeft: 10,
                }}
              >
                {settings.name}
              </Typography>
            </div>
          </Link>
          <div className={classes.grow} />
          {decoded.type < 4 && (
            <IconButton
              component={Link}
              to="/notice"
              color={notice.length - readNotice.length ? "primary" : "none"}
              // style={{ marginRight: 10 }}
            >
              <Badge
                badgeContent={notice.length - readNotice.length}
                color="error"
              >
                <Notifications fontSize="large" />
              </Badge>
            </IconButton>
          )}
          <IconButton
            edge="end"
            onClick={(e) => setOpenMenu(e.currentTarget)}
            color="primary"
            style={{ marginRight: 0 }}
          >
            {profile.photo ? (
              <img
                alt={profile.name}
                src={`${baseurl}${profile.photo}`}
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 15,
                  border: `1px solid ${getColor.primary}`,
                  objectFit: "cover",
                }}
              />
            ) : (
              <AccountCircle fontSize="large" />
            )}
          </IconButton>
          {renderMenu()}
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader} />
        <List
          dense
          style={{
            background: getColor.primary,
            height: "100%",
          }}
        >
          {decoded.type === 5 || decoded.type === 4
            ? renderAdminSideBar()
            : decoded.type === 3
            ? renderFacultySideBar()
            : renderStudentSideBar()}
        </List>
      </SwipeableDrawer>
      <Drawer
        className={classes.drawerDesktop}
        anchor="left"
        variant="persistent"
        open={true}
        classes={{
          paper: classes.drawerPaperDesktop,
        }}
      >
        <div style={{ height: 54 }} />

        <List
          dense
          style={{
            background: getColor.primary,
            height: "100%",
            overflowY: "auto",
          }}
        >
          {decoded.type === 5 || decoded.type === 4
            ? renderAdminSideBar()
            : decoded.type === 3
            ? renderFacultySideBar()
            : renderStudentSideBar()}
        </List>
      </Drawer>
      <main className={classes.content}>{children}</main>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    decoded: state.decoded,
    profile: state.profile,
    notice: state.notice,
    grievance: state.grievance,
    readNotice: state.readNotice,
  };
};
export default connect(mapStateToProps, { logout })(Navbar);
